<template>
    <Actions 
        :record="record" 
        @edit="startEdit" 
        @delete="deleteSprint(record.id)" 
        @updateStatus="updateStatus"/></template>
<script>
import Actions from '@apps/vue2TaskComponent/components/Sprint/components/Actions.vue'

export default {
    props: {
        openTableSettings: {
            type: Function,
            default: () => {}
        },
        record: {
            type: Object,
            required: true
        },
        startEdit: {
            type: Function,
            default: () => {}
        },
        deleteSprint: {
            type: Function,
            default: () => {}
        },
        updateStatus: {
            type: Function,
            default: () => {}
        },
    },
    components: {
        Actions
    }
}
</script>